import React from "react";
import PropTypes from "prop-types";

ComingSoon.propTypes = {};

export default function ComingSoon({}) {
  return (
    <section className="h-[100vh] flex flex-col items-center justify-center bg-[#F4FEF6]">
      <h1 className="font-heading font-bold text-6xl text-[#144E07] mb-6 text-center px-4">
        Kanso Health
      </h1>
      <h2 className="font-heading font-thin text-3xl ">Coming Soon</h2>
    </section>
  );
}
